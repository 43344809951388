import { defineComponent, ref } from 'vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
import { getPlayerInfo } from '@/assets/ts/api/google-spreadsheets';
import Modal from '@/components/layout/modal/Modal.vue';
export default defineComponent({
    name: 'NicknameRow',
    components: { Modal, CTAButton },
    props: {
        profile: {
            type: Object,
            required: true
        }
    },
    setup() {
        const playerInfoModal = ref(null);
        const playerData = ref(null);
        const errorInfoModal = ref(null);
        const showPlayerInfo = (profileId) => {
            playerData.value = null;
            getPlayerInfo(profileId).then((response) => {
                playerData.value = {
                    profile_id: response.profile_id,
                    currency: response.currency,
                    kills: response.kills,
                    max_kills: response.max_kills,
                };
                toggleModal();
            }).catch(() => {
                toggleErrorModal();
            });
        };
        const toggleModal = () => playerInfoModal.value.toggleModal();
        const toggleErrorModal = () => errorInfoModal.value.toggleModal();
        return {
            errorInfoModal,
            playerData,
            playerInfoModal,
            showPlayerInfo,
            toggleErrorModal,
            toggleModal
        };
    }
});
