import { defineComponent, onMounted, ref } from 'vue';
import { getNicknames } from '@/assets/ts/api/nickname';
import RightAside from '@/components/layout/asides/right-aside/RightAside.vue';
import NicknameRow from '@/components/nicknames/nicknames-list/nickname-row/NicknameRow.vue';
export default defineComponent({
    name: 'AchievementList',
    components: {
        NicknameRow,
        RightAside
    },
    setup() {
        const profiles = ref([]);
        const searchingNote = ref('');
        const isFiltered = ref(false);
        onMounted(() => loadNicknames());
        const loadNicknames = (event = {}) => {
            let searchingNoteTemp = '';
            isFiltered.value = false;
            getNicknames(event).then((response) => {
                profiles.value = response === null || response === void 0 ? void 0 : response.data;
                if (response === null || response === void 0 ? void 0 : response.nickname) {
                    searchingNoteTemp += `Gracze o nicku podobnym do: ${response.nickname}`;
                    isFiltered.value = true;
                }
                else if (response === null || response === void 0 ? void 0 : response.profile_id) {
                    searchingNoteTemp += `Gracze o ID podobnym do: ${response.profile_id}`;
                    isFiltered.value = true;
                }
            }).finally(() => searchingNote.value = searchingNoteTemp);
        };
        const filterList = (filterParams) => loadNicknames(filterParams);
        return { filterList, isFiltered, profiles, searchingNote };
    }
});
