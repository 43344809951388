import { defineComponent, ref } from 'vue';
import NicknameToggler from '@/components/core/filters/nickname-toggler/NicknameToggler.vue';
import TextFilter from '@/components/core/filters/text-filter/TextFilter.vue';
import FilterAside from '@/components/layout/asides/filter-aside/FilterAside.vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    name: 'NicknamesFilters',
    components: {
        CTAButton,
        FilterAside,
        NicknameToggler,
        TextFilter
    },
    emits: [
        'filter'
    ],
    setup(props, context) {
        const profileIdFilter = ref();
        const nicknameFilter = ref();
        const isFilteredByPlayer = ref(false);
        const filterByPlayer = (isFilter) => isFilteredByPlayer.value = isFilter;
        function filter() {
            var _a;
            const achievementFilter = {
                profile_id: ((_a = profileIdFilter === null || profileIdFilter === void 0 ? void 0 : profileIdFilter.value) === null || _a === void 0 ? void 0 : _a.inputData) ? profileIdFilter.value.inputData : null,
                nickname: (nicknameFilter === null || nicknameFilter === void 0 ? void 0 : nicknameFilter.value) ? nicknameFilter.value.inputData : null
            };
            context.emit('filter', achievementFilter);
        }
        return {
            isFilteredByPlayer,
            nicknameFilter,
            profileIdFilter,
            filterByPlayer,
            filter
        };
    }
});
