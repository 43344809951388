import { defineComponent, ref } from 'vue';
import Subpage from '@/components/layout/subpage/Subpage.vue';
import NicknamesList from '@/components/nicknames/nicknames-list/NicknamesList.vue';
import NicknamesFilters from '@/components/nicknames/nicknames-filters/NicknamesFilters.vue';
export default defineComponent({
    components: {
        Subpage,
        NicknamesFilters,
        NicknamesList,
    },
    setup() {
        const nicknamesList = ref();
        const onFilter = (event) => nicknamesList.value.filterList(event);
        return {
            nicknamesList,
            onFilter
        };
    }
});
